// @flow
import React from "react";
import ReactDOM from "react-dom";

// import Navbar from 'lib/navbar';
// import Signup from 'lib/idd-signup';
// import Lineup from "lib/lineup-display";
import { load } from "lib/commonloads";

load();

// let lineup = document.getElementById("react-lineup");
// if (lineup) ReactDOM.render(<Lineup roomId="" timelineId="" />, lineup);
